@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
*{margin:0;padding:0}
body{margin:0;font-size:14px;background:#fafdfb;color:#272727;font-family:Montserrat,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.App,.formMain,body,html{height:100vh}
img{max-width:100%}
ol,ul{margin:0;padding:0;list-style:none}
a:focus,a:hover,button:focus{text-decoration:none;color:inherit;outline:0;box-shadow:none}
h1,h2,h3,h4,h5,h6{font-weight:700;color:#2e99fb;font-family:Montserrat,sans-serif}
.fa-x{font-size:1.395em!important}
.swal-button{padding:7px 19px;border-radius:5px;font-size:12px;text-shadow:0 -1px 0 rgba(0,0,0,.3)}
.swal-button--confirm{padding:7px 19px;border-radius:5px;font-size:12px;background-color:#004a99;color:#fff;text-shadow:0 -1px 0 rgba(0,0,0,.3)}
.swal-button--confirm:hover{background-color:#0468d3;color:#fff}
.pagination{border-radius:0;justify-content:center;padding:0;margin-top:1rem}
.pagination>li{display:inline-block;padding-left:0}
.pagination>li{list-style:none;border:0;margin:0 .1rem}
.pagination>li>a,.pagination>li>span{position:relative;float:left;padding:4px 10px;line-height:1.42857143;text-decoration:none;background:#080015;color:#fff!important;border:0;margin-left:0}
.pagination>li.active>a{color:#fff;background:#2e99fb}
.pagination>li>a:hover{background-color:#2e99fb;color:#fff}
.pdf-viewer{width:600px;height:700px}
.navbar{padding:.5rem 0}
.navbar-expand{background:#2e99fb}
.topbar #sidebarToggleTop{color:#fff;padding:0}
.sidebar-light .sidebar-brand{color:#fafafa;background-color:#2e99fb}
.sidebar .sidebar-brand .sidebar-brand-text{text-transform:uppercase;font-size:23px;text-shadow:2px 2px 2px #66666670;letter-spacing:1.5px}
.topbar .topbar-divider{width:0;border-right:1px solid #e3e6f0;height:0;margin:0}
footer.sticky-footer{padding:1rem 0}
.breadcrumb{display:flex;flex-wrap:wrap;padding:0;margin-bottom:0;list-style:none;background-color:transparent;border-radius:0}
.breadcrumb-item a{color:#2e99fb}
.sidebar .sidebar-heading{margin-bottom:1rem}
.sidebar-light .nav-item .nav-link:hover{background:#2e99fb;color:#fff}
.sidebar-light .nav-item .nav-link:hover i,.sidebar-light .nav-item.active .nav-link:hover i{color:#fff}
.sidebar-light .nav-item .nav-link[data-toggle=collapse]:hover::after{color:#fff}
.sidebar .nav-item .collapse,.sidebar .nav-item .collapsing{margin:0}
.sidebar .nav-item .collapse .collapse-inner,.sidebar .nav-item .collapsing .collapse-inner{padding:0;margin:0 0 1rem 0;box-shadow:none;background:0 0}
.sidebar .nav-item .collapse .collapse-inner .collapse-item,.sidebar .nav-item .collapsing .collapse-inner .collapse-item{padding:.5rem 1rem;margin:0 .5rem;display:block;color:#fff;text-decoration:none;border-radius:0;white-space:nowrap;background:#58adfb}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover{color:#fff;background:#272727}
.sidebar .nav-item .nav-link{padding:.5rem 1rem}
.navbar-expand .navbar-nav .dropdown-menu{position:absolute;padding:0;border:0}
.navbar-expand .navbar-nav .dropdown-menu:before{content:'';width:0;height:0;border-style:solid;border-width:10px;border-color:transparent transparent #fff transparent;position:absolute;top:-20px;right:22px}
.dropdown-divider{display:none}
.dropdown-item{padding:.5rem;border:0;border-bottom:1px solid #eee}
.custom-select,.form-control{font-size:13px}
.card-header{border:0}
.card-header:first-child{border:0}
.card .table td,.card .table th{padding-right:1rem;padding-left:1rem;font-size:14px;vertical-align:middle}
.card .table th{font-weight:600}
.card .table td .fa.fa-pencil-square{color:#2e99fb}
.card .table th:nth-child(5),.card .table th:nth-child(7){min-width:120px}
.table thead th{border-bottom:1px solid #dee2e6}
.table td .badge{font-weight:600;height:20px;line-height:13px}
.modal-header{padding:.5rem 1rem}
.modal-body{max-height:450px;overflow-y:auto}
.modal-body::-webkit-scrollbar{width:5px}
.modal-body::-webkit-scrollbar-thumb{background-color:#d3d3d3}
.uploadForm .card .table td i, .card .table td i{width:27px;height:27px;line-height:20px;text-align:center;font-size:12px;font-weight:300;border-radius:.3rem; padding: 3px; margin-top: 8px;}
.card .table td .fa-trash-o{background:red;color:#fff}
.card .table td .fa-pencil{background:#0468d3;color:#fff}
.uploadForm .card .table td i {background:#0468d3;color:#fff;font-weight: 900;cursor: pointer;}
.form-group ul{display:flex;flex-flow:row wrap}
.form-group ul li{border:2px solid #2e99fb50;background:#2e99fb0f;color:#2e99fb;border-radius:.25rem;-webkit-border-radius:.25rem;-moz-border-radius:.25rem;-ms-border-radius:.25rem;-o-border-radius:.25rem;margin-right:.5rem;margin-bottom:.5rem;padding:.2rem .5rem;cursor:pointer}
.swal-modal {width: 32%;max-width: 850px;}
.swal-content {max-height: calc(100vh - 68px);overflow-y: auto;}
.swal-content::-webkit-scrollbar{width:5px}
.swal-content::-webkit-scrollbar-thumb{background-color:#d3d3d3}
.swal-content video {background: #eee;padding: 0.5rem;}
.swal-content span{display:inline-block;margin:20px 15px 0 0;text-align:left;cursor:pointer}
.swal-content span:first-child{margin-left:15px}
.swal-content span svg{width:40px;height:auto;fill:#2e99fb}
#wrapper #content-wrapper{height:100vh;transition:all .3s ease-in-out;-webkit-transition:all .3s ease-in-out}
.sidebar{position:fixed;left:0;top:0;min-height:100vh;width:14rem;background-color:#fff;-webkit-box-shadow:0 .15rem 1.75rem 0 rgba(58,59,69,.15);box-shadow:0 .15rem 1.75rem 0 rgba(58,59,69,.15);z-index:111;transition:all .3s ease-in-out;-webkit-transition:all .3s ease-in-out}
.sidebarClose{transform:translateX(-100%);-webkit-transform:translateX(-100%);transition:all .3s ease-in-out 70ms;-webkit-transition:all .3s ease-in-out 70ms}
.sidebarOpen~#content-wrapper{margin-left:14rem}
.uploadedBy tr{border-bottom:1px solid #dee2e6!important}
.uploadedBy tr:last-child{border-bottom:0!important}
.profilePage{padding:2rem 0}
.profilePage form{background:#fff;box-shadow:0 0 50px -20px rgba(0,0,0,.2);padding:1.5rem;border-radius:.3rem;-webkit-border-radius:.3rem;-moz-border-radius:.3rem;-ms-border-radius:.3rem;-o-border-radius:.3rem}
.profilePage form input{font-size:small}
.profilePage .fileUpload{text-align:center;display:block;cursor:pointer;border:2px dashed rgba(0,0,0,.2);padding:2rem;margin:0}
.profilePage .fileUpload svg{width:220px;height:auto;margin:auto auto 1rem;display:block}
.text_key { margin-left: -40rem;}
.fileDrag {cursor: pointer;}
.fileDrag * {outline: none;}
.fileDrag svg, .fileDrag img { width: 100px; height: auto; margin-bottom: 1rem;}
.fileDrag img { width: 150px; margin-bottom: 1.5rem;}

.uploadForm {padding-top: 1rem;color: #272727; padding-bottom: 4rem;}
.stepsContent {border: 2px dashed #272727;display: flex;align-items: center;justify-content: center;padding: 3rem 1rem;min-height: 200px;text-align: center;}
.navSteps {display: flex;margin: 0 0 3rem;padding: 0;list-style: none;justify-content: center;align-items: center;color: #272727;text-transform: uppercase;font-size: 13px;}
.navSteps li {font-weight: 700;margin: 0 2rem;position: relative;min-width: 65px;text-align: center;}
.navSteps li span { display: block; margin: auto auto 1.5rem; width:7px; height: 7px; border-radius: 100px; -webkit-border-radius: 100px; -moz-border-radius: 100px; -ms-border-radius: 100px; -o-border-radius: 100px;background: #272727;position: relative;}
.navSteps li.active span:after {background: #eeeeee;position: absolute;content: '';width: 40px;height: 40px;top: 50%;left: 50%;border-radius: 100px;transform: translate(-50%, -50%);-webkit-transform: translate(-50%, -50%);-moz-transform: translate(-50%, -50%);-ms-transform: translate(-50%, -50%);-o-transform: translate(-50%, -50%);z-index: -1;}
.navSteps li:before {background: #eeeeee;position: absolute;content: '';width: 200%;height: 2px;top: 3px;left: 50%;z-index: -2;}
.navSteps li:last-child:before {display: none;}
.uploadSteps {color: #272727;}
.uploadSteps li span {background: #272727;}
.uploadSteps li.active span:after {background: #eeeeee;}
.uploadSteps li:before {background: #eeeeee;width: 220%;}
.uploadForm .btn-primary, .uploadForm .btn-outline-primary {padding: 0.7rem 2.5rem; min-width: 130px; border-radius: 2px; -webkit-border-radius: 2px; -moz-border-radius: 2px; -ms-border-radius: 2px; -o-border-radius: 2px;font-family: 'Montserrat', sans-serif;text-transform: uppercase;font-size: 12px;font-weight: 700;border-color: #272727;}
.uploadForm .btn-primary {background: #272727;color: #fff;}
.uploadForm .btn-outline-primary {background: none;color: #272727;border: 1px solid #272727;}
.uploadForm .btn-outline-primary.focus, .uploadForm .btn-outline-primary:focus, .uploadForm .btn-primary.focus, .uploadForm .btn-primary:focus {box-shadow: none}
.switchDiv input[type=checkbox]{height: 0;width: 0;visibility: hidden;}
.switchDiv label {cursor: pointer;text-indent: -9999px;width: 80px;height: 40px;background: #27272747;display: block;border-radius: 5px;position: relative;margin: 0.3rem auto auto;}
.switchDiv label:after {content: '';position: absolute;top: 5px;left: 5px;width: 30px;height: 30px;background: #fff;border-radius: 3px;transition: 0.3s;}
.switchDiv input:checked + label {background: #272727;}
.switchDiv input:checked + label:after {left: auto;right: 5px;}
.task-list {
	display: flex !important;
	/* width: 52%; */
	margin: 0 0 10px 0;
}

.main_s {
	border: 2px dashed;
	text-align: center;
	padding: 40px 0px;
}
.upload_video {
	padding: 24px 0px;
	/* border: 4px solid #2e99fb; */
	margin: 20px 0px;
	background: #fff;
	border: 1px solid rgba(0,0,0,.125);
	box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15) !important;
}
.heading {
	text-align: center;
	margin: 0 auto;
}
.video_sec {
	padding-bottom: 15px;
}
.video_sec_content {
	margin-left: 15px;
	margin-right: 15px;
}
.video_sec_content .preview_file_name{
	font-size: 14px;
	font-weight: 500;
}
.preview_file_status {
	font-size: 11px;
	font-weight: 600;
	text-transform: uppercase;
	border-radius: 5px;
	text-align: center;
	color: #000;
	background-color: #ededed;
}
.preview_status_waiting{
	color: #000;
	background-color: #ededed;
}
.preview_status_completed{
	color: #fff;
	background-color: #037529;
}
.preview_status_failed{
	color: #fff;
	background-color: #ce1e1e;
}
.drop_s .btn-outline-primary {
	margin: 0 auto;
}

.uploadForm .btn-primary {
	margin: 0 auto;
}

.addVideo.btn.btn-primary.btn-sm {
    background: #007bff;
    border: 0;
    padding: 6px;
    border-radius: .2rem;
}
.drop_s {
	border: 2px dashed;
	padding: 16px 15px;
	width: 100%;
	
}
.trash_icon {
	position: absolute;
	right: 23px;
	background: #2e99fb;
	cursor: pointer;
	color: #fff;
	padding: 5px 0px;
	border-radius: 50px;
	width: 34px;
	height: 34px;
	text-align: center;
	top: -6px;
	border: 2px solid;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item, .sidebar .nav-item .collapsing .collapse-inner .collapse-item  {
    color: #fff;
    background: #272727;
}

.selectedItem {
	padding: .5rem 1rem;
	margin: 0 .5rem;
	display: block;
	color: #fff;
	text-decoration: none;
	border-radius: 0;
	white-space: nowrap;
	background: #58adfb;
}

.list-script-actions > span { display: block; text-align: center; }


button.swal-button.swal-button--confirm {color: #fff;background: #2e99fb;}
@media (max-width:767px) {.sidebarClose{transform:none;-webkit-transform:none}.sidebarOpen{transform:translateX(-100%);-webkit-transform:translateX(-100%)}.sidebarOpen~#content-wrapper{margin-left:0}}
@media (max-width:575px) {.profilePage .fileUpload{margin-bottom:1.2rem}}
.seclect-script-download svg{
	width: 30px;
	margin: 0 auto;
	cursor: pointer;
}
.seclect-script-download a{
	margin: 0 auto;
	display: flex;
	justify-content: center;
}


.new-form-m {
	width: 60%;
	margin: 0 auto;
	background: #fff;
	padding: 20px;
	box-shadow: 2px 2px 2px 2px #eee;
	border-radius: 10px;
}

.new-form-m .form-control {
	word-wrap: normal;
	height: 40px;
	padding-left: 4px;
	border-radius: 5px;
	border-color: #cdcdcd;
	/* margin-left: 15px; */
	width: 100%;
  }

  .date-section-m .form-group {
	position: relative;
  }

  .date-section-m .form-group i {
    position: absolute;
    right: 1rem;
    top: 42px;
    color: #888;
}